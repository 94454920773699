<template>
<v-form ref="umbralesform" v-model="valid" lazy-validation >
    <div v-if="form_data.steriplex === false">

      <div v-if="form_data.data_ensayo.tipoRes !== 2">
        <div  v-if="matrizunidad.ali == 1">
              <div v-if="activealimento">
                <v-subheader>
                Alimentos
                </v-subheader>
                <v-divider></v-divider>
                <v-row v-for="(item, index) in form_data.data_umbrales.alimentosSelect"
                :key="item.id">
                    <v-col cols="12" md="7" >
                      <template>
                        <div>
                          <treeselect
                          :limit="5"
                          v-model="item.rsa_id"
                          :multiple="true"
                          :options="listaAlimentos"
                          :value-consists-of="valueConsistsOf"
                          :max-height="350"
                          placeholder="Alimentos"
                          />
                        </div>
                      </template>
                    </v-col>
                    <v-col cols="12" md="2" >
                        <v-text-field
                        name="umbral"
                        type="number"
                        v-model="item.umbral"
                        label="UMBRAL"
                        outlined
                        dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" >
                      <v-text-field
                        name="unidades"
                        v-model="item.unidades"
                        label="Unidades"
                        outlined
                        dense
                        ></v-text-field>
                      <!-- <p>{{form_data.matrices_unidades[0].unidades}}</p> -->
                    </v-col>
                    <v-col cols="12" md="1" >
                        <v-btn
                          x-small class="ma-2"
                          v-if="index==0" outlined fab color="teal"
                          v-on:click="additemAlimento()"
                        >
                        <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                        x-small class="ma-2"
                        v-if="index>0" outlined fab
                        color="error" v-on:click="deleteitemUmbral(index,'A')"
                        >
                        <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-col>
                  </v-row>
              </div>

              <div v-if="activesuperficie">
                  <v-subheader>
                  Superficies
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row v-for="(item, index) in form_data.data_umbrales.superficiesSelect"
                  :key="item.zona">
                      <v-col cols="12" md="7" >
                          <!-- <v-autocomplete
                          :items="listaSuperficies"
                          v-model="item.rsa_id"
                          :rules="rules.tipoMuestra"
                          item-text="grupo"
                          item-value="id_rsa"
                          label="SUPERFCIES"
                          outlined
                          multiple="multiple"
                          dense
                          @blur="onChangesuperficie($event)"
                          ></v-autocomplete> -->

                          <!-- <treeselect
                          :limit="5"
                          v-model="item.rsa_id"
                          :multiple="true"
                          :options="listaSuperficies"
                          :value-consists-of="valueConsistsOf"
                          :max-height="350"
                          placeholder="Superficies"
                          /> -->
                          <template>
                            <div>
                              <treeselect
                              :limit="5"
                              v-model="item.rsa_id"
                              :multiple="true"
                              :options="listaSuperficies"
                              :value-consists-of="valueConsistsOf"
                              :max-height="350"
                              placeholder="Superficies"
                              />
                            </div>
                          </template>
                      </v-col>
                      <v-col cols="12" md="2" >
                          <v-text-field
                          name="umbral"
                          type="number"
                          label="UMBRAL"
                          outlined
                          dense
                          :rules="rules.umbral"
                          v-model="item.umbral"
                          multiple="multiple"
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                        name="unidades"
                        v-model="item.unidades"
                        label="Unidades"
                        outlined
                        dense
                        ></v-text-field>
                        <!-- <p>{{form_data.matrices_unidades[1].unidades}}</p> -->
                      </v-col>
                      <v-col cols="12" md="1" >
                          <v-btn x-small class="ma-2" v-if="index==0"
                          outlined fab color="teal" v-on:click="additemSuperficie()">
                              <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn x-small class="ma-2" v-if="index>0"
                            outlined fab color="error" v-on:click="deleteitemUmbral(index,'S')">
                              <v-icon>mdi-minus</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>

              <div v-if="activemanipulador">
                  <v-subheader>
                  Manipuladores
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row v-for="(item, index) in form_data.data_umbrales.manipuldoresSelect"
                  :key="'M'+item.id">
                      <v-col cols="12" md="7" >
                          <v-autocomplete
                          :items="listaManipuladores"
                          :rules="rules.tipoMuestra"
                          v-model="item.rsa_id"
                          item-text="grupo"
                          item-value="id_rsa"
                          label="MANIPULADORES"
                          outlined
                          dense
                          multiple="multiple"
                          @blur="onChangemanupulador($event)"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2" >
                          <v-text-field
                          name="umbral"
                          type="number"
                          label="UMBRAL"
                          :rules="rules.umbral"
                          v-model="item.umbral"
                          outlined
                          dense
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                        name="unidades"
                        v-model="item.unidades"
                        label="Unidades"
                        outlined
                        dense
                        ></v-text-field>
                        <!-- <p>{{form_data.matrices_unidades[2].unidades}}</p> -->
                      </v-col>
                      <v-col cols="12" md="1" >
                          <v-btn x-small class="ma-2" v-if="index==0"
                          outlined fab color="teal" v-on:click="additemManipuladores()">
                              <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn x-small class="ma-2" v-if="index>0"
                            outlined fab color="error" v-on:click="deleteitemUmbral(index,'M')">
                              <v-icon>mdi-minus</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>

              <div v-if="activeaguas">
                  <v-subheader>
                  Aguas
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row v-for="(item, index) in form_data.data_umbrales.aguasSelect"
                  :key="'W'+item.id">
                      <v-col cols="12" md="7" >
                          <!-- <v-autocomplete
                          label="AGUAS"
                          item-text="grupo"
                          :items="listaAguas"
                          item-value="id_rsa"
                          v-model="item.rsa_id"
                          :rules="rules.tipoMuestra"
                          outlined
                          dense
                          multiple="multiple"
                          @blur="onChangeaguas($event)"
                          ></v-autocomplete> -->

                          <template>
                            <div>
                              <treeselect
                              :limit="5"
                              v-model="item.rsa_id"
                              :multiple="true"
                              :options="listaAguas"
                              :value-consists-of="valueConsistsOf"
                              :max-height="350"
                              placeholder="Aguas"
                              />
                            </div>
                          </template>
                      </v-col>
                      <v-col cols="12" md="2" >
                          <v-text-field
                          name="umbral"
                          type="number"
                          label="UMBRAL"
                          :rules="rules.umbral"
                          v-model="item.umbral"
                          outlined
                          dense
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                        name="unidades"
                        v-model="item.unidades"
                        label="Unidades"
                        outlined
                        dense
                        ></v-text-field>
                        <!-- <p>{{form_data.matrices_unidades[3].unidades}}</p> -->
                      </v-col>
                      <v-col cols="12" md="1" >
                          <v-btn x-small class="ma-2" v-if="index==0"
                          outlined fab color="teal" v-on:click="additemAguas()">
                              <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn x-small class="ma-2" v-if="index>0"
                            outlined fab color="error" v-on:click="deleteitemUmbral(index,'W')">
                              <v-icon>mdi-minus</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>

              <div v-if="activeambientes">
                  <v-subheader>
                  Ambientes
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-row v-for="(item, index) in form_data.data_umbrales.ambientesSelect"
                  :key="'E'+item.id">
                      <v-col cols="12" md="7" >
                          <v-autocomplete
                          :items="listaAmbiente"
                          v-model="item.rsa_id"
                          :rules="rules.tipoMuestra"
                          item-text="grupo"
                          item-value="id_rsa"
                          label="AMBIENTES"
                          outlined
                          dense
                          multiple="multiple"
                          @blur="onChangeambientes($event)"
                          ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="2" >
                          <v-text-field
                          name="umbral"
                          type="number"
                          label="UMBRAL"
                          :rules="rules.umbral"
                          v-model="item.umbral"
                          outlined
                          dense
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                        name="unidades"
                        v-model="item.unidades"
                        label="Unidades"
                        outlined
                        dense
                        ></v-text-field>
                        <!-- <p>{{form_data.matrices_unidades[4].unidades}}</p> -->
                      </v-col>
                      <v-col cols="12" md="1" >
                          <v-btn x-small class="ma-2" v-if="index==0"
                          outlined fab color="teal" v-on:click="additemAmbientes()">
                              <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn x-small class="ma-2" v-if="index>0"
                            outlined fab color="error" v-on:click="deleteitemUmbral(index,'E')">
                              <v-icon>mdi-minus</v-icon>
                          </v-btn>
                      </v-col>
                  </v-row>
              </div>
          </div>
        </div>
        <div v-else>
          <p>No aplican Umbrales para el tipo resultado "Ausencia / Presencia"</p>
        </div>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="12" >
          <p class="font-weight-black">PARAMETROS Y NIVEL DE PELIGROSIDAD</p>
        </v-col>
        <v-col cols="3" md="3"  align="center"
              v-for="(item) in form_data.parametrosSelected"
              :key="item.id">
                <v-select
                  :items="data_ensayo.nivelPeligro"
                  :label="item.nombre"
                  v-model="item.peligrosidadID"
                  outlined
                  item-text="descripcion"
                  item-value="id"
                ></v-select>
              </v-col>
      </v-row>
    </div>
</v-form>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default {
  components: {Treeselect},
  name: 'Umbrales',
  data: () => ({
    valid: false,
    form_dataens: {
      data_ensayo: {
        alimentosUmbral: [],
      },
    },
    alimentosarray: [
      {
        id: 0,
        rsa_id: [],
        umbral: '',
      }
    ],
    superficiesarray: [
      {
        id: 0,
        rsa_id: [],
        umbral: '',
      }
    ],
    manupuladoresarray: [
      {
        id: 0,
        rsa_id: [],
        umbral: '',
      }
    ],
    aguasarray: [
      {
        id: 0,
        rsa_id: [],
        umbral: '',
      }
    ],
    ambientesarray: [
      {
        id: 0,
        rsa_id: [],
        umbral: '',
      }
    ],
    selectedFruits: [],
    valueConsistsOf: 'LEAF_PRIORITY',
    rules: {
      umbral: [
        (v) => !!v || 'Umbral es requerido',
      ],
      tipoMuestra: [
        (v) => (v.length > 0) || 'Tipo muestra es requerido  ',
      ],
    },
  }),
  beforeCreate() {

  },
  computed: {
    ...mapState('Ensayos', ['form_data','data_ensayo', 'matrizunidad', 'alimentosSelect','superficiesSelect']),
    activealimento() {
      return this.form_data.matrices_unidades[0].activo;
    },
    activesuperficie() {
      return this.form_data.matrices_unidades[1].activo;
    },
    activemanipulador() {
      return this.form_data.matrices_unidades[2].activo;
    },
    activeaguas() {
      return this.form_data.matrices_unidades[3].activo;
    },
    activeambientes() {
      return this.form_data.matrices_unidades[4].activo;
    },
    listaAlimentos() {
      const listaalim = [];
      this.data_ensayo.rsa.forEach(element => {
        if(element.matriz_id == 12){
          element.active = true;
          element.id = element.id_rsa+'_R';
          element.label = element.grupo;
          let array_children = [];
          element.rsasubs.forEach(element2 => {
            element2.id = element2.id_alimentos+'_S_'+element2.puntoRSA;
            element2.label = element2.descripcion
            array_children.push(element2)
          });
          element.children=array_children;
          listaalim.push(element)
        }
      });

      return listaalim;
    },
    listaSuperficies(){
        // const listasup = [];
        // this.data_ensayo.rsa.forEach(element => {
        //   if(element.matriz_id == 9){
        //     listasup.push(element)
        //   }
        // });
        // console.log('lista superficie' , listasup);
        // return listasup;
        const listasup = [];
        this.data_ensayo.rsa.forEach(element => {
          if(element.matriz_id === 9){
            element.active = true;
            element.id = element.id_rsa+'_R';
            element.label = element.grupo;
            let array_children = [];
            element.rsasubs.forEach(element2 => {
              element2.id = element2.id_alimentos+'_S_'+element2.puntoRSA;
              element2.label = element2.descripcion
              array_children.push(element2)
            });
            element.children=array_children;
            listasup.push(element)
          }
        });
        return listasup;
    },
    listaManipuladores() {
      const listaman = [];
      this.data_ensayo.rsa.forEach(element => {
        if(element.matriz_id == 8){
          listaman.push(element)
        }
      });
      return listaman;
    },
    listaAguas() {
      // const listaag = [];
      // this.data_ensayo.rsa.forEach(element => {
      //   if(element.matriz_id == 7){
      //     listaag.push(element)
      //   }
      // });
      // return listaag;

      const listasup = [];
      this.data_ensayo.rsa.forEach(element => {
        if(element.matriz_id == 7){
          element.active = true;
          element.id = element.id_rsa+'_R';
          element.label = element.grupo;
          let array_children = [];
          element.rsasubs.forEach(element2 => {
            element2.id = element2.id_alimentos+'_S_'+element2.puntoRSA;
            element2.label = element2.descripcion
            array_children.push(element2)
          });
          element.children=array_children;
          listasup.push(element)
        }
      });
      return listasup;
    },
    listaAmbiente() {
      const listaamb = [];
      this.data_ensayo.rsa.forEach(element => {
        if(element.matriz_id == 6){
          listaamb.push(element)
        }
      });
      return listaamb;
    },
    likesAllFruit () {
      return this.selectedFruits.length === this.listaAlimentos.length
    },
    likesSomeFruit () {
      return this.selectedFruits.length > 0 && !this.likesAllFruit
    },
    icon () {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    multiple(newValue) {
      if (newValue) {
        this.value = this.value ? [ this.value ] : []
      } else {
        this.value = this.value[0]
      }
    },
  },
  methods: {
    ...mapMutations('Ensayos', ['setPatSelected', 'setState', 'setRSA', 'pushAlimentosArray', 'addAlimento']),
    validate() {
      return this.$refs.umbralesform.validate();
    },
    edit_tecnica_pat(enabled, id_pat) {
      let found = false;
      this.form_data.patogenos_selected.map((v) => {
        if (v.cod_pat === id_pat.cod_pat) { found = true; }
      });
      // console.log([this.form_data.patogenos_selected , id_pat.cod_pat ] );
      if (found) {
        // this.dialog = enabled;
        this.setPatSelected(id_pat);
      } else if (!found) {
        this.snackbar = true;
      }
    },
    clear() {
      // this.dialog = false;
    },
    additemAlimento() {
      const id = this.form_data.data_umbrales.alimentosSelect.length + 1;
      this.form_data.data_umbrales.alimentosSelect.push({
        id:id,
        rsa_id: [],
        umbral: 0,
      });
      this.alimentosarray = this.form_data.data_umbrales.alimentosSelect;
      // console.log('A=',this.form_data.data_umbrales.alimentosSelect);
      // console.log('S=',this.form_data.data_umbrales.superficiesSelect);
      // console.log('F=',this.form_data);
    },
    deleteitemUmbral(index, matriz) {
      console.log(index, matriz);
      switch (matriz) {
        case 'A':
          // this.form_data.alimentosSelect.splice(index, 1);
          // this.alimentosarray = this.form_data.alimentosSelect;
          this.form_data.data_umbrales.alimentosSelect.splice(index, 1);
          this.alimentosarray = this.form_data.data_umbrales.alimentosSelect;
          break;
        case 'S':
          // this.form_data.superficiesSelect.splice(index, 1);
          // this.superficiesarray = this.form_data.superficiesSelect;
          this.form_data.data_umbrales.superficiesSelect.splice(index, 1);
          this.superficiesarray = this.form_data.data_umbrales.superficiesSelect;
          break;
        case 'M':
          // this.form_data.manipuldoresSelect.splice(index, 1);
          // this.manupuladoresarray = this.form_data.manipuldoresSelect;
          this.form_data.data_umbrales.manipuldoresSelect.splice(index, 1);
          this.manupuladoresarray = this.form_data.data_umbrales.manipuldoresSelect;
          break;
        case 'W':
          // this.form_data.aguasSelect.splice(index, 1);
          // this.aguasarray = this.form_data.aguasSelect;
          this.form_data.data_umbrales.aguasSelect.splice(index, 1);
          this.aguasarray = this.form_data.data_umbrales.aguasSelect;
          break;
        case 'E':
          // this.form_data.ambientesSelect.splice(index, 1);
          // this.ambientesarray = this.form_data.ambientesSelect;
          this.form_data.data_umbrales.ambientesSelect.splice(index, 1);
          this.ambientesarray = this.form_data.data_umbrales.ambientesSelect;
          break;
      }
    },
    additemSuperficie() {
      // console.log('agrega sup');
      const id = this.form_data.data_umbrales.superficiesSelect.length + 1;
      // if (this.form_data.data_umbrales.superficiesSelect.length == 3) {
      //   return false;
      // }
      this.form_data.data_umbrales.superficiesSelect.push({
        id: id,
        rsa_id: [],
        umbral: 0,
      });
      this.superficiesarray = this.form_data.data_umbrales.superficiesSelect;
    },
    onChange(ev) {
      const list = [];
      // const selects = this.form_data.alimentosSelect;
      const selects = this.alimentosarray;
      selects.forEach((element) => {
        if(element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }

      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      // console.log('list', this.listaAlimentos);
      // console.log(list);
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // console.log(restan);
      // this.listaAlimentos = restan;
      // this.setRSA(restan)
    },
    onChangesuperficie(ev) {
      const list = [];
      // const selects = this.form_data.superficiesSelect;
      const selects = this.superficiesarray;
      console.log(selects);
      selects.forEach((element) => {
        if (element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid);
          }
        }

      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaSuperficies.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaSuperficies = restan;
      */
    },
    additemManipuladores() {
      const id = this.form_data.data_umbrales.manipuldoresSelect.length + 1;
      this.form_data.data_umbrales.manipuldoresSelect.push({
        id,
        rsa_id: [],
        umbral: 0,
      });
      this.manupuladoresarray = this.form_data.data_umbrales.manipuldoresSelect;
    },
    onChangemanupulador(ev) {
      const list = [];
      // const selects = this.form_data.manipuldoresSelect;
      const selects = this.manupuladoresarray;
      console.log(selects);
      selects.forEach((element) => {
        if (element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaManipuladores.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaManipuladores = restan;
      */
    },
    additemAguas() {
      // if (this.form_data.data_umbrales.aguasSelect.length == 2) {
      //   return false;
      // }
      const id = this.form_data.data_umbrales.aguasSelect.length + 1;
      this.form_data.data_umbrales.aguasSelect.push({
        id,
        rsa_id: [],
        umbral: 0,
      });

      this.aguasarray = this.form_data.data_umbrales.aguasSelect;
    },
    onChangeaguas(ev) {
      const list = [];
      // const selects = this.form_data.aguasSelect;
      const selects = this.aguasarray;
      console.log(selects);
      selects.forEach((element) => {
        if(element.rsa_id){
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaAguas.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaAguas = restan;
      */
    },
    additemAmbientes() {
      if (this.form_data.data_umbrales.ambientesSelect.length == 2) {
        return false;
      }
      const id = this.form_data.data_umbrales.ambientesSelect.length + 1;
      this.form_data.data_umbrales.ambientesSelect.push({
        id,
        rsa_id: [],
        umbral: 0,
      });
      this.ambientesarray = this.form_data.data_umbrales.ambientesSelect;
    },
    onChangeambientes(ev) {
      const list = [];
      // const selects = this.form_data.ambientesSelect;
      const selects = this.ambientesarray;
      console.log(selects);
      selects.forEach((element) => {
        if(element.rsa_id){
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaAmbiente.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaAmbiente = restan;
      */
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedFruits = []
        } else {
          this.selectedFruits = this.listaAlimentos.slice()
        }
      })
    },
  },

};
</script>
